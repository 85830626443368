const customCSSPropertiesMap: Record<string, string> = {
  offsetTop: '--awsui-offset-top-lgrg0d',
  offsetTopWithNotifications: '--awsui-offset-top-with-notifications-lgrg0d',
  contentGapLeft: '--awsui-content-gap-left-lgrg0d',
  contentGapRight: '--awsui-content-gap-right-lgrg0d',
  contentHeight: '--awsui-content-height-lgrg0d',
  defaultMaxContentWidth: '--awsui-default-max-content-width-lgrg0d',
  defaultMinContentWidth: '--awsui-default-min-content-width-lgrg0d',
  footerHeight: '--awsui-footer-height-lgrg0d',
  headerHeight: '--awsui-header-height-lgrg0d',
  layoutWidth: '--awsui-layout-width-lgrg0d',
  mainOffsetLeft: '--awsui-main-offset-left-lgrg0d',
  maxContentWidth: '--awsui-max-content-width-lgrg0d',
  minContentWidth: '--awsui-min-content-width-lgrg0d',
  notificationsHeight: '--awsui-notifications-height-lgrg0d',
  overlapHeight: '--awsui-overlap-height-lgrg0d',
  navigationWidth: '--awsui-navigation-width-lgrg0d',
  splitPanelReportedHeaderSize: '--awsui-split-panel-reported-header-size-lgrg0d',
  splitPanelReportedSize: '--awsui-split-panel-reported-size-lgrg0d',
  splitPanelHeight: '--awsui-split-panel-height-lgrg0d',
  splitPanelMinWidth: '--awsui-split-panel-min-width-lgrg0d',
  splitPanelMaxWidth: '--awsui-split-panel-max-width-lgrg0d',
  toolsMaxWidth: '--awsui-tools-max-width-lgrg0d',
  toolsWidth: '--awsui-tools-width-lgrg0d',
  toolsAnimationStartingOpacity: '--awsui-tools-animation-starting-opacity-lgrg0d',
  contentScrollMargin: '--awsui-content-scroll-margin-lgrg0d',
  flashbarStackDepth: '--awsui-flashbar-stack-depth-lgrg0d',
  flashbarStackIndex: '--awsui-flashbar-stack-index-lgrg0d',
  flashbarStickyBottomMargin: '--awsui-flashbar-sticky-bottom-margin-lgrg0d',
  stackedNotificationsBottomMargin: '--awsui-stacked-notifications-bottom-margin-lgrg0d',
  stackedNotificationsDefaultBottomMargin: '--awsui-stacked-notifications-default-bottom-margin-lgrg0d',
};
export default customCSSPropertiesMap;
